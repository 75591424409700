<template>
<ButtonComponent class="button-div"/>
</template>

<script setup>

</script>

<style scoped>
.button-div {
    cursor: pointer;
    border: none;
}
</style>