<template>
    <div class="container background-gris">
        <NavBar />
        <button type="button" class="btn btn-primary mt-3 bg-black fg-white font-bold border-black"
            @click="router.go(-1)"><i class="bi bi-arrow-return-left mr-2 font-bold"></i> Back</button>
        <div class="row">
            <div class="col-12">
                <h1 class="text-center mt-4">Termos e Condições Gerais</h1>
                <p>
                    Estes Termos e Condições (“Termos”) regem o uso do serviço de armazenamento de malas ou mochilas em
                    cacifos oferecidos pela BagageLocker, doravante designada como “Prestador de Serviços”, através do
                    seu site www.bagagelocker.com que é o único site valido e oficial pelo qual os clientes que desejam
                    adquirir o serviço proposto. Ao utilizar os serviços do Prestador de Serviços, está a concordar com
                    estes termos na sua totalidade. Se não concorda com estes Termos, não deve utilizar os o site do
                    Prestador de Serviços.
                </p>
                <ol>
                    <h3>
                        <li>Serviço Prestado:</li>
                    </h3>
                    <ol>
                        <li>A empresa oferece um serviço de armazenamento seguro de malas ou mochilas em cacifos
                            disponíveis em locais designados.</li>
                        <li>Os Clientes podem reservar um cacifo para armazenar os seus pertences durante um período
                            específico, mediante o pagamento das taxas correspondentes.</li>
                        <li>O Cliente deve ter pelo menos 18 anos de idade, ser legalmente capaz de contratar e usar
                            este site de acordo com os Termos e Condições em vigor na data de validação da sua reserva.
                        </li>
                        <li>O Cliente declara a veracidade e exatidão de todas as informações que comunica ao Prestador
                            de Serviços no âmbito da sua reserva e, consequentemente, a BagageLocker impede qualquer
                            ação ou reivindicação de terceiros quanto a uma utilização ilícita ou fraudulenta dos seus
                            dados por outra pessoa no âmbito da utilização dos Serviços.</li>
                    </ol>
                    <h3>
                        <li>Reservas:</li>
                    </h3>
                    <ol>
                        <li>As reservas de cacifos são feitas através do site do Prestador de Serviços.</li>
                        <li>O Cliente é responsável por fornecer informações precisas durante o processo de reserva,
                            incluindo informações de contato e o período de armazenamento desejado.</li>
                        <li>O Prestador de Serviços reserva-se o direito de recusar ou cancelar reservas se considerar
                            que estão em violação destes Termos ou de qualquer política interna da Empresa do Prestador
                            de Serviços.</li>
                        <li>O Prestador de Serviços envia via um e-mail e/ou SMS com a confirmação da reserva com e com
                            os códigos necessários para a utilização do ou dos cacifos reservados no site.</li>
                        <li>Este e-mail e/ou SMS de confirmação é transmitido para o endereço de e-mail e/ou número de
                            telefone móvel fornecidos pelo cliente no formulário de reserva.</li>
                        <li>O e-mail de confirmação e/ou SMS de confirmação de reserva enviado pela BagageLocker com os
                            códigos de reserva vale como reserva e nenhum outro documento será enviado ao Cliente.</li>
                    </ol>
                    <h3>
                        <li>Taxas e Pagamentos:</li>
                    </h3>
                    <ol>
                        <li>Os Serviços são fornecidos aos preços em vigor indicados no site da Internet do Prestador de
                            Serviços.</li>
                        <li>O Cliente concorda em pagar as taxas de armazenamento conforme indicado no momento da
                            reserva.</li>
                        <li>As taxas de armazenamento são calculadas com base na duração do período de armazenamento do
                            cacifo utilizado.</li>
                        <li>O Cliente autoriza o Prestador de Serviços a cobrar o método de pagamento fornecido no
                            momento da reserva.</li>
                        <li>Esses preços são fixos durante seu período de validade, ou seja, após confirmação duma
                            reserva, conforme indicado no site da Internet.</li>
                        <li>O Prestador de Serviços, tem o direito de, fora desse período de validade, de modificar os
                            preços a qualquer momento. Os preços não incluem eventuais custos de processamento bancário,
                            câmbio, etc., que são cobrados a mais de acordo com as condições em vigor do banco emissor
                            do pagamento.</li>
                        <li>O preço inclui o IVA aplicável no dia da reserva. O pagamento da reserva é feito online por
                            um sistema de pagamento seguro.</li>
                        <li>Os meios de pagamento seguros possíveis são: cartões de crédito (Visa/Mastercard), PayPal,
                            Apple Pay e Google Pay. O contrato assim concluído online pelo Cliente no site do Prestador
                            de Serviços torna-se firme e definitivo.</li>
                        <li>O pedido de reserva é considerado definitivo após o Cliente ter pago o preço total da
                            reserva devida à Prestadora de Serviços. Qualquer rejeição de pagamento, independentemente
                            da causa, implica o abandono imediato do pedido de reserva.</li>
                        <li>A Prestadora de Serviços não será responsável pelo imposto na alínea anterior, e a reserva
                            não será registada. O Cliente reconhece que os dados registados pela Prestadora de Serviços
                            no âmbito das reservas efetuadas no Site constituem a prova da transação financeira.</li>
                    </ol>
                    <h3>
                        <li>Responsabilidade do Usuário:</li>
                    </h3>
                    <ol>
                        <li>O Cliente é responsável por embalar adequadamente os seus pertences antes de os armazenar
                            nos cacifos da Prestadora de Serviços.</li>
                        <li>O Cliente é responsável por se assegurar das dimensões e do peso das suas bagagens antes de
                            qualquer reserva no Site, sendo que, nenhuma reclamação ou reembolso será considerada, caso
                            a bagagem não esteja conforme a estas dimensões e peso considerado.</li>
                        <li>O Cliente concorda em não armazenar itens proibidos nos cacifos, incluindo, mas não se
                            limitando a, itens perigosos, inflamáveis, ilegais ou perecíveis.</li>
                        <li>É de igual forma, responsabilidade do cliente:
                            <ol>
                                <li>Manter a porta do cacifo fechada quando não estiver a aceder ao cacifo;</li>
                                <li>Manter a porta dos cacifos e (do espaço da BagageLocker) bem fechada quando sai do
                                    espaço da Prestadora de Serviços;</li>
                                <li>Garantir que as suas bagagens estão bem fechadas (código, cadeado, ...) e
                                    identificadas (etiqueta com nome e número de telefone de contacto do Cliente);</li>
                                <li>Não depositar objetos de grande valor, pelos quais o Cliente permanece responsável e
                                    pelos quais o Prestador de Serviços declina qualquer responsabilidade;</li>
                                <li>Não deixar animais nas bagagens;</li>
                                <li>Não deixar nada no interior do cacifo nem no espaço interior quando sai
                                    definitivamente do espaço (opção CHECK OUT) do BagageLocker.</li>
                            </ol>
                        </li>
                        <li>É da responsabilidade do Cliente não deixar nenhum documento respeitante a alguma viagem no
                            interior do cacifo, nem qualquer documento tomando todas as precauções necessárias
                            (documento de identificação, carta de condução, passaporte…) – EXEMPLO DE DOCUMENTOS QU NÃO
                            DEVEM SER DEIXADOS NAS MALAS POR PRECAUÇÃO. Caso contrário, o Prestador de Serviços não
                            poderá, em caso algum, ser responsabilizado, mesmo em causo de roubo.</li>
                        <li>Por motivos de segurança, qualquer objeto ou bagagem esquecido/encontrado e não reclamado
                            será, depois de devidamente anunciado o achado na nossa página, levado à posse da
                            BagageLocker (cfr. artigo 1323.º do Código Civil) e, por consequência, entregue a
                            instituições ou destruído.</li>
                        <li>Os clientes têm a possibilidade de entrar e sair quantas vezes quiserem entre as 8h e as
                            22h, desde que tenham uma reserva ativa.</li>
                        <li>Em caso de expedição de um objeto esquecido, todos os custos são da responsabilidade do
                            Cliente, pagos antecipadamente, com um mínimo de 25€ (vinte cinco euros) aos quais se
                            juntarão os custos de envio em função do peso, do destino, do modo de envio (normal, UPS,
                            etc.).</li>
                        <li>É da responsabilidade do Cliente verificar, no momento da sua reserva definitiva, a exatidão
                            das informações que comunica e, em particular, o indicativo do pais no número de telemóvel e
                            endereço de e-mail.</li>
                    </ol>
                    <h3>
                        <li>Responsabilidade do Prestador de Serviços:</li>
                    </h3>
                    <ol>
                        <li>O Prestador de Serviços compromete-se a manter os cacifos em condições adequadas de
                            segurança e limpeza.</li>
                        <li>A Empresa não se responsabiliza por perdas, danos ou roubo dos pertences armazenados nos
                            cacifos, exceto nos casos de negligência comprovada por parte da Empresa.</li>
                        <li>Em caso algum o Prestador de Serviços pode ser responsabilizado por uma bagagem
                            danificada/roubada durante a expedição e por qualquer bagagem que não chegue a destino e
                            objetos ou bagagens deixadas no local e que não se encontravam guardadas no cacifo
                            atribuído.</li>
                        <li>Além disso, a Prestadora de Serviços não poderá ser responsabilizada em caso de uso
                            fraudulento por terceiros, da identidade ou dos meios de pagamento do Cliente.</li>
                        <li>É da responsabilidade do Cliente verificar, no momento da sua reserva definitiva, que vale
                            contrato, a exatidão das informações que comunica e, em particular, o código do país +
                            número de telemóvel e endereço de e-mail.</li>
                    </ol>
                    <br>
                    A BagageLOCKER não pode ser responsabilizada pelas consequências resultantes de uma
                    indisponibilidade temporária do Site ou por problemas eventuais de conexão entre os equipamentos do
                    cliente (computadores, telefone celular) e o Site da BagageLOCKER ocorrendo durante uma solicitação
                    ou validação de reserva. Nenhuma reclamação ou reembolso será recebida se o cliente não preencher
                    corretamente o formulário de reserva.
                    <br>
                    <h3>
                        <li>Cancelamentos e Reembolsos:</li>
                    </h3>
                    <ol>
                        <!--<li>
                            O Cliente pode cancelar uma reserva até as 08h da manhã do dia anterior ao dia do check in,
                            neste caso o cliente recebera então um reembolso integral. Caso contrario, o Prestador de
                            Serviços receberá o pagamento integral da reserva, sujeita a quaisquer taxas aplicáveis.
                        </li>
                        <li>
                            Os reembolsos estão sujeitos às políticas de reembolso da Prestadora de Serviços (cláusula
                            anterior) e podem estar sujeitos a taxas administrativas. (neste caso este ponto já não faz
                            sentido penso eu)
                        </li>-->
                        <li>
                            Para fazer valer os seus direitos, o Cliente deverá informar o Prestador de Serviços, por
                            escrito, da existência dos defeitos de conformidade sem demoras a contar da Prestação dos
                            Serviços.
                        </li>
                        <li>
                            O Prestador de Serviços reembolsará ou corrigirá ou fará corrigir (na medida do possível) os
                            serviços julgados defeituosos o mais breve possível após a constatação pelo mesmo do defeito
                            ou vício. O reembolso será feito por crédito via o meio do pagamento do Cliente.
                        </li>
                        <li>
                            A garantia do Prestador de Serviços é limitada ao reembolso dos Serviços efetivamente pagos
                            pelo Cliente e o Prestador não poderá ser considerado responsável nem por qualquer atraso ou
                            inexecução resultante da ocorrência de um caso de força maior habitualmente reconhecido pela
                            jurisprudência portuguesa.
                        </li>
                    </ol>
                    <h3>
                        <li>Seguro:</li>
                    </h3>
                    <ol>
                        <li>
                            As malas e/ou mochilas armazenadas nos nossos cacifos estão cobertos por seguro para um
                            valor máximo de 300 EUR por cacifo, mediante apresentação de comprovativo de reserva e
                            excluindo todos os produtos proibidos. Ageas Seguros Apólice N°: 0095.10.135534
                        </li>
                        <li>
                            Esta cobertura de seguro reembolsa ao cliente todos os danos e/ou perdas diretamente
                            imputáveis ao Prestador de Serviços nos limites acima mencionados, bem como em caso de roubo
                            comprovado por terceiros. Desde que as malas e/ou mochilas armazenadas estejam no cacifo
                            atribuído no momento da reserva e que este esteja fechado.
                        </li>
                        <li>
                            A cobertura de seguro não está disponível para pedras preciosas, metais preciosos, relógios,
                            ecrãs plasma, ecrãs LCD, sistemas de navegação por satélite, telemóveis, computadores,
                            tablets, qualquer equipamento eletrónico, jóias, dinheiro, vidro, porcelana, objetos de arte, antiguidades, documentos ou
                            filmes, fitas, discos, cartões de memória ou qualquer outro suporte que contenha dados ou
                            imagens.
                        </li>
                        <li>
                            O seguro mencionado acima não cobre arranhões nas bagagens e/ou mochilas, quebra de alças
                            e/ou rodas. Para mais detalhes sobre as condições e a cobertura do seguro, contacte-nos
                        </li>
                        <li>
                            Todos os espaços da Prestador de Serviços são controlados com um sistema de videovigilância
                            24 horas por dia, em rede com um controlo central de acesso às imagens, que o usuário aceita
                            e dá consentimento para guardar as imagens até 30 dias, conforme prevê a lei.
                        </li>
                        <li>
                            A Prestadora de Serviços, iniciará todas as ações legais que considerar necessárias em caso
                            de má utilização ou deterioração dos meios colocados à disposição dos seus clientes.
                        </li>
                        <li>
                            Qualquer reclamação, seja qual for, não será
                            admissível após um prazo de 48 horas após a data e hora de devolução das bagagens.
                        </li>
                    </ol>
                    <h3>
                        <li>PRIVACIDADE:</li>
                    </h3>
                    <p>
                        Durante a utilização do nosso Site, durante a execução de um contrato, a Prestador de Serviços
                        pode usar as suas informações pessoais. Para mais informações sobre o uso das suas informações
                        pessoais, aceda à seguinte hiperligação Política de Privacidade (tenho que criar uma politica de
                        privacidade ? ou o que segue é suficiente). Os dados recolhidos neste site destinam-se ao
                        processamento das reservas e comunicação com os Clientes, processamento de pedidos de informação
                        e de eventuais reclamações, análise estatística, bem como a respetiva utilização para efeitos de
                        marketing direto.
                        A Prestadora de Serviços garante a confidencialidade de todos os dados fornecidos pelos seus
                        clientes. Não obstante, proceder à recolha e ao tratamento de dados de forma segura e que impede
                        a sua perda ou manipulação, utilizando as técnicas mais aperfeiçoadas para o efeito, informamos
                        que a recolha em rede aberta permite a circulação dos dados pessoais sem condições de segurança,
                        correndo o risco de ser vistos e utilizados por terceiros não autorizados.
                    </p>
                    <h3>
                        <li>COOKIES:</li>
                    </h3>
                    <p>
                        O que são cookies?
                        <br>
                        Para poder prestar um serviço mais personalizado, este site utiliza cookies para recolher e
                        guardar informação. Um cookie é um pequeno pacote de dados que é armazenado no equipamento a
                        partir do qual se efetua acesso a determinadas páginas web.
                        <br>
                        Este tipo de ficheiros não pode danificar o seu equipamento. Os cookies permitem a uma página
                        Web, entre outras coisas, armazenar e recuperar informação sobre a utilização do sítio Web,
                        permitindo-nos melhorar a sua qualidade e oferecer-lhe uma maior experiência de utilização. Na
                        página da Wikipedia http://pt.wikipedia.org/wiki/Cookie_(informática) é possível obter mais
                        informação sobre o que são os cookies e de como trabalhar com eles.
                        <br>
                        Ao navegar no nosso website o visitante aceita que podemos colocar cookies no seu dispositivo e
                        aceder aos mesmos quando visitar o site no futuro.
                        <br>
                        Se pretender eliminar quaisquer cookies que já se encontrem no seu computador, consulte a área
                        de ajuda e apoio técnico no seu navegador da Internet para obter instruções sobre a forma de
                        localizar o ficheiro ou diretório que armazena os cookies.
                        <br>
                        Pode encontrar mais informações sobre cookies, como os impedir e como os apagar em
                        www.aboutcookies.org. Tenha em atenção que se eliminar os nossos cookies ou desativar cookies
                        futuros poderá não conseguir aceder a determinadas áreas ou funcionalidades do nosso site.
                        <br>
                        Este website utiliza estes tipos de cookies:
                        <br>
                        Cookies de cariz técnico
                        <br>
                        Estes cookies permitem navegar no site e usar funcionalidades como o carrinho de compras.
                        <br>
                        Cookies de cariz analítico do site
                        <br>
                        Este site utiliza cookies do Google Analytics de modo a quantificar o número de visitantes.
                        Estes cookies servem para analisar e medir a forma como os utilizadores navegam pela Internet.
                        Esta informação permite a este website melhorar continuadamente os seus serviços e a experiência
                        de utilização do mesmo e compra de produtos e serviços por parte dos utilizadores. Para obter
                        mais informações, pode visitar a página de privacidade do Google Analytics:
                        http://www.google.com/analytics/learn/privacy.html
                        Também utilizamos cookies da Criteo, especialista em criar publicidade personalizada. Para obter
                        mais informações, pode consultar a página de privacidade da Criteo em
                        http://www.criteo.com/br/privacy/
                        <br>
                        Cookies de personalização
                        <br>
                        Quando um utilizador navega e/ou faz compras on-line, o website recordar-se das suas
                        preferências (por exemplo nome de utilizador, idioma ou localização). Isto torna a experiência
                        do utilizador em termos de navegação mais fácil, simples e pessoal.
                    </p>
                    <h3>
                        <li>Alterações nos Termos e Condições:</li>
                    </h3>
                    <ol>
                        <li>Ao prestador de serviços reserva-se o direito de atualizar ou modificar estes Termos a
                            qualquer momento, sem aviso prévio.</li>
                        <li>O uso continuado dos serviços da Empresa após quaisquer alterações constitui aceitação
                            dessas alterações.</li>
                    </ol>
                    <h3>
                        <li>Lei Aplicável:</li>
                    </h3>
                    <p>
                        Estes Termos são regidos e interpretados de acordo com as leis de Portugal, e quaisquer disputas
                        decorrentes ou relacionadas a estes Termos serão exclusivamente submetidas à jurisdição dos
                        tribunais competentes do País.
                    </p>
                    <p>
                        Ao utilizar os serviços da Empresa, o usuário confirma ter lido, entendido e concordado com estes
                        Termos e Condições na sua totalidade. Se não concordar com estes Termos, não deve utilizar os
                        serviços da Empresa.
                    </p>
                </ol>

            </div>
        </div>
    </div>
</template>
<script setup>
import NavBar from '@/components/base/en/NavBar.vue';
import { useRouter } from 'vue-router'
const router = useRouter()
</script>
<style scoped>
.background-gris {
    width: 100%;
    height: 100%;
    margin-top: 60px;
    overflow: hidden;
    background: #ededed;
    font-family: Montserrat;
}
</style>