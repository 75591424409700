<template>
    <meta name="description"
        content="Learn about our confidentiality policy. Discover how we protect your privacy, manage your data, and ensure your information is secure. Your trust is our priority.">
    <div class="container background-gris">
        <NavBar />
        <button type="button" class="btn btn-primary mt-3 bg-black fg-white font-bold border-black"
            @click="router.go(-1)"><i class="bi bi-arrow-return-left mr-2 font-bold"></i> Back</button>
        <div class="row">
            <div class="col-12">
                <h1 class="text-center mt-4">Política de Privacidade</h1>
                <ol>
                    <h3>
                        <li>Generalidades</li>
                    </h3>
                    <p>Bagage Locker valoriza a sua privacidade e a proteção dos seus dados.<br>
                        Esta política visa informá-lo sobre as nossas práticas relativas à coleta, uso e
                        compartilhamento de informações que você nos fornece através do nosso site.<br>
                        Esta política (juntamente com as Condições Gerais de Venda) descreve como tratamos os dados
                        pessoais que coletamos e que você nos fornece. Leia atentivamente este documento para
                        conhecer e compreender as nossas práticas de tratamento dos seus dados pessoais.
                    </p>
                    <h3>
                        <li>Coleta das informações</li>
                    </h3>
                    <p>Base legal para coleta de dados pessoais:
                    </p>
                    <ol>
                        <li>
                            <b>Execução de contrato;</b> quando o cliente se inscreve no site, coletamos os seus dados
                            para cumprir o contrato.
                        </li>
                        <li>
                            <b>Interesse legítimo:</b> enviamos newsletters e emails informativos para informar o
                            cliente sobre os nossos serviços e desenvolver a nossa atividade.
                        </li>
                        <li>
                            <b>Consentimento:</b> para cookies de redes sociais, cookies de publicidade e cookies do
                            Google Analytics, coletamos seus dados com seu consentimento.
                        </li>

                        <b>Os Seus dados pessoais são coletados para as seguintes finalidades:</b>
                        <ul>
                            <li>Gerenciamento de acesso aos serviços e utilização do site</li>
                            <li>Criação de arquivos com os dados dos clientes e para fins de gestão interna prospects:
                            </li>
                            <li>Envio de newsletters, solicitações e mensagens promocionais</li>
                            <li>Gestão de avaliações de produtos, serviços e conteúdos</li>
                            <li>Personalização de respostas a solicitações de informação</li>
                            <li>Cumprimento de obrigações legais e regulatórias</li>
                        </ul>
                        No momento da coleta dos seus dados pessoais, informamos se alguns dados são obrigatórios ou
                        facultativos. Os dados obrigatórios são necessários para o funcionamento da prestação de serviço
                        (aluguer de cacife) sem eles, o serviço não pode funcionar corretamente. No que diz respeito aos
                        dados facultativos, o cliente é totalmente livre de fornecer ou não esses dados, eles não são
                        essenciais para o funcionamento dos serviços.
                        <p>Informações transmitidas pelo cliente diretamente</p>
                        <li><b>Dados recolhidos pelo cliente</b></li>
                        Ao utilizar o site <a href="https://bagagelocker.com">www.bagagelocker.com</a> o cliente vai nos
                        transmitir dados pessoais a partir do momento em que preenche o formulário de inscrição ou no
                        caso em que o cliente nos contacte que seja por telefone email ou qualquer outro meio
                        <li><b>Dados recolhidos automaticamente via redes sociais</b></li>
                        <ol>
                            <li>Ao se conectar aos nossos serviços usando as funcionalidades de redes sociais, a Bagage
                                Locker terá acesso a alguns dos seus dados (como nome, apelido, endereço de email, etc.)
                                da sua conta na rede social, de acordo com os termos de uso da rede social em
                                questão.<br>
                                Também podemos coletar alguns dos seus dados pessoais quando você interage com as
                                funcionalidades de redes sociais, como a função "Like".
                            </li>
                            <li>Durante cada uma das suas visitas, podemos coletar, de acordo com a legislação aplicável
                                e com o seu consentimento, quando necessário, informações sobre os dispositivos que você
                                utiliza para acessar aos nossos serviços ou as redes a partir das quais você acessa aos
                                nossos serviços nomeadamente endereço IP, Dados de conexão, tipo e versão do navegador,
                                tipos e versões de plugins do navegador, sistema operacional e plataforma, o seu
                                histórico de navegação em nossas plataformas, o conteúdo que você acessou ou visualizou,
                                os erros de download
                                <b>Para coletar essas informações, utilizamos diversas tecnologias, incluindo
                                    cookies.</b>
                            </li>
                            <li>Também coletamos informações sobre como você utiliza a nossa plataforma, como o número
                                de cacifes reservados, a taxa de resposta às mensagens, a data de inscrição …</li>
                        </ol>
                        <li>
                            <b>Duração de conservação dos seus dados</b><br>
                            Os dados pessoais dos clientes são armazenados enquanto você mantiver uma conta connosco.
                        </li>
                        <li>
                            <b>Como utilizamos os seus dados pessoais</b><br>
                            Utilizamos os dados que coletamos para:
                            <ul>
                                <li>Fornecer serviços personalizados aos usuários.</li>
                                <li>Nos ajudar a analisar e melhorar os serviços que oferecemos.</li>
                            </ul>
                        </li>
                    </ol>
                    <h3>
                        <li>Publicidade</li>
                    </h3>
                    <p>De acordo com a legislação aplicável e com o seu consentimento, poderemos usar os dados que você
                        nos fornece no site <a href="https://bagagelocker.com">www.bagagelocker.com</a> para fins de
                        prospeção comercial (por exemplo, enviar
                        newsletters, convites para eventos ou qualquer outra comunicação que possa lhe interessar e
                        exibir anúncios direcionados em plataformas de mídia social ou sites de terceiros).<br>
                        <b>Em relação aos e-mails promocionais:</b><br>
                        O cliente pode retirar o seu consentimento a qualquer momento, clicando no link de
                        descadastramento fornecido em cada uma de nossas comunicações;<br>
                        <b>Em relação à publicidade direcionada:</b>
                        Em plataformas de redes sociais (por exemplo, Facebook, Twitter): o cliente pode se opor a este
                        tratamento a qualquer momento configurando as configurações de publicidade da sua conta; ou em
                        sites terçeiros<br>
                    </p>
                    <h3>
                        <li>Direitos sobre os seus dados pessoais</li>
                    </h3>
                    <ol>
                        <li>
                            O cliente tem o direito de obter uma cópia dos seus dados pessoais que mantemos disponíveis,
                            bem como de solicitar a sua retificação ou exclusão. Antes de atender à sua solicitação, é
                            possível que tenhamos que verificar a sua identidade e solicitar que você nos forneça mais
                            informações para processar a sua solicitação.
                        </li>
                        <li>
                            Esforçaremos nos para responder à sua solicitação dentro de um prazo razoável e dentro dos
                            prazos fixados por lei. Se você deseja exercer este direito, entre em contato connosco na
                            secção contato.
                            <br>Poderemos manter algumas informações acerca de você quando a lei nos obriga a fazê-lo ou
                            quando temos um motivo legítimo para isso. Este tipo de caso ocorre, por exemplo, se
                            acreditarmos que você cometeu uma fraude ou violou as nossas Condições Gerais de Venda e
                            queremos evitar que contorne as regras aplicáveis à nossa comunidade.
                        </li>
                        <li>Tem o direito de apresentar uma reclamação, se você considerar que o tratamento dos seus
                            dados pessoais foram objeto duma violação das leis aplicáveis.<br>
                            Este recurso pode ser exercido sem prejuízo de qualquer outro recurso perante um tribunal
                            administrativo ou judicial. De fato, você também tem o direito de um recurso administrativo
                            ou
                            judicial efetivo se considerar que o tratamento de seus dados pessoais objeto desta
                            constitui
                            uma violação das leis aplicáveis.
                        </li>
                        <li>
                            Você tem o direito de obter a limitação do tratamento de seus dados pessoais, nos seguintes
                            casos:
                            <ul>
                                <li>
                                    Durante o período de verificação que implementamos, quando você contesta a exatidão
                                    de seus
                                    dados pessoais.
                                </li>
                                <li>
                                    Quando o tratamento desses dados é ilícito e você deseja limitar esse tratamento em
                                    vez de
                                    excluir seus dados.
                                </li>
                                <li>
                                    Quando não precisamos mais dos seus dados pessoais, mas você deseja que os
                                    conservemos para
                                    exercer seus direitos.
                                </li>
                                <li>
                                    Durante o período de verificação dos motivos legítimos, quando você se opôs ao
                                    tratamento de
                                    seus dados pessoais.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <h3>
                        <li>Confidencialidade da senha</li>
                    </h3>
                    <p>Você é responsável pela confidencialidade da senha que você escolheu para acessar sua conta no
                        nosso site <a href="https://bagagelocker.com">www.bagagelocker.com</a>
                    </p>
                    <h3>
                        <li>Política de privacidade via links</li>
                    </h3>
                    <p>O site <a href="https://bagagelocker.com">www.bagagelocker.com</a> pode conter links para sites
                        de nossos parceiros ou de terceiros. Observe que esses sites têm as suas próprias políticas de
                        privacidade e não nos responsabilizamos pelo uso que esses sites fazem das informações coletadas
                        quando você clica nesses links. Convidamos você a ler as políticas de privacidade desses sites
                        antes de fornecer seus dados pessoais.
                    </p>
                    <h3>
                        <li>Contato</li>
                    </h3>
                    <p>Para qualquer dúvida relacionada à presente política de privacidade ou para qualquer solicitação
                        relativa aos seus dados pessoais, você pode nos contatar por e-mail para o endereço
                        contact@bagagelocker.com
                    </p>
                </ol>
            </div>
        </div>
    </div>
</template>
<script setup>
import NavBar from '@/components/base/en/NavBar.vue';
import { useRouter } from 'vue-router'
const router = useRouter()
</script>
<style scoped>
DIV>OL {
    counter-reset: item;
}

DIV>OL>LI::before {
    content: counter(item) " ";
    counter-increment: item
}

LI>OL {
    counter-reset: subitem;
}

LI>OL>LI::before {
    content: counter(item) "." counters(subitem, ".") " ";
    counter-increment: subitem
}

.background-gris {
    width: 100%;
    height: 100%;
    margin-top: 60px;
    overflow: hidden;
    background: #ededed;
    font-family: Montserrat;
}
</style>