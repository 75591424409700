<template>
  <div id="app">
    <router-view class="routed-content"></router-view>
  </div>
</template>

<script setup>
</script>

<style scoped>
#app {
  position: relative;
}
</style>
